<template>
  <div>
    <center>
      <br>
        <b-row class="justify-content-center mt-2">
         <b-col cols="10" md="8" class="text-center">
            <h2 style="color:#fff;">Muchas felicidades  <b style="color:#5cb617;">¡Disfruta la playa!</b></h2>
            <b-row class="justify-content-center mt-5" v-if="user.canal == 3">
                <b-col cols="10" md="8" >
                    <br><br>

                    <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <h4 style="color:#5cb617;">EJECUTIVOS</h4>
                            <hr>
                            <p style="color:#fff;" v-for="gan in arrayGan" :key="gan.id" v-show="gan.perfil == 'Ejecutivo'">
                                {{gan.nombre}}
                            </p>
                        </div>
                        <div class="carousel-item" v-if="user.perfil != 'Ejecutivo'">
                                <h4 style="color:#5cb617;">GERENTES</h4>
                            <hr>
                            <p style="color:#fff;" v-for="gan in arrayGan" :key="gan.id" v-show="gan.perfil == 'Gerente'">
                                {{gan.nombre}}
                            </p>
                        </div>
                        <div class="carousel-item" v-if="user.perfil == 'Coordinador'">
                            <h4 style="color:#5cb617;">COORDINADORES</h4>
                            <hr>
                            <p style="color:#fff;" v-for="gan in arrayGan" :key="gan.id" v-show="gan.perfil == 'Coordinador'">
                                {{gan.nombre}}
                            </p>
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                    </div>
                    
       
                </b-col>
                <!-- <b-col cols="10" md="4" v-if="user.perfil != 'Ejecutivo'">
                    <br><br>
                    <h4 style="color:#5cb617;">Gerente</h4>
                    <hr>
                    <p style="color:#fff;" v-for="gan in arrayGan" :key="gan.id" v-show="gan.perfil == 'Gerente'">
                        {{gan.nombre}}
                    </p>
                </b-col>
                <b-col cols="10" md="4" v-if="user.perfil == 'Coordinador'">
                    <br><br>
                    <h4 style="color:#5cb617;">Coordinador</h4>
                    <hr>
                    <p style="color:#fff;" v-for="gan in arrayGan" :key="gan.id" v-show="gan.perfil == 'Coordinador'">
                        {{gan.nombre}}
                    </p>
                </b-col> -->
            </b-row>

            <b-row class="justify-content-center mt-2" v-if="user.canal == 2">
                <b-col cols="10" md="4" >
                    <br><br>
                    <h4 style="color:#5cb617;">CONCURSANTES</h4>
                    <hr>
                    <p style="color:#fff;" v-for="gan in arrayGan" :key="gan.id" >
                        {{gan.nombre}}
                    </p>
                </b-col>
            </b-row>

            <b-row class="justify-content-center mt-2" v-if="user.canal == 1">
                    <b-col cols="10" md="8" >
                        <br><br>
                      <div id="carouselExampleControls2" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls2" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>
                            <div class="carousel-item active">
                            <h4 style="color:#5cb617;">PROMOTORES</h4>
                                <hr>
                                <p style="color:#fff;" v-for="gan in arrayGan" :key="gan.id" v-show="gan.perfil == 'PROMOTOR'">
                                    {{gan.nombre}}
                                </p>
                            </div>
                            <div class="carousel-item" v-if="user.perfil != 'Genérico'">
                                <h4 style="color:#5cb617;">SUPERVISORES</h4>
                                <hr>
                                <p style="color:#fff;" v-for="gan in arrayGan" :key="gan.id" v-show="gan.perfil == 'SUPERVISOR'">
                                    {{gan.nombre}}
                                </p>
                            </div>
                            <div class="carousel-item" v-if="user.perfil == 'Lider'">
                                <h4 style="color:#5cb617;">LIDERES</h4>
                                <hr>
                                <p style="color:#fff;" v-for="gan in arrayGan" :key="gan.id" v-show="gan.perfil == 'LIDER'">
                                    {{gan.nombre}}
                                </p>
                            </div>
                        </div>
  
                        </div>
                    
                    </b-col>
            </b-row>
          </b-col>
        </b-row>
    </center>
  </div>
</template>

<script>
export default {
  name: 'ganadores',
  // components:{
  //   tlmkt,
  //   propio,
  //   retail
  // },
  data(){
    return{
      isMobile: this.$mobile(),
      arrayGan:[]
    }
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    }
  },
  methods:{
    getGanadores(){
         var url= 'ranking/ganadores?canal='+ this.user.canal;
            this.$api.get(url).then(
                ({data}) => {
                    console.log(data);
                    this.arrayGan = data.ganadores;                 
                }
            );
    }
  },
  mounted(){
     this.getGanadores();
     this.$confetti.start({
        particles: [
            {
            type: 'rect',
            },
            {
            type: 'circle',
            },
        ],
        defaultColors: [
            '#5cb617',
            '#ffffff',
            '#187abe'
        ],
    });
    setTimeout(() => {
        this.$confetti.stop();
    }, 4000);
  }
}
</script>

<style>

</style>